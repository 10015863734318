var LazyImage = (function() {
	function LazyImage() {
		var items = $('lazy-image');

		this.register(items)
	}

	LazyImage.prototype.register = function(items) {
		if(window.IntersectionObserver) {
			const observer = new IntersectionObserver((entries) => {
			    $(entries).each(function (index, entry) {
			        if (entry.isIntersecting === true) {
			        	let src = $(entry.target).attr('src');
			        	let alt = $(entry.target).attr('alt');

			        	const img = document.createElement('img');

			        	img.src = src;
			        	img.alt = alt;
			        	img.width = $(entry.target).css('--w');
			        	img.height = $(entry.target).css('--h');

			        	entry.target.appendChild(img);

			        	img.addEventListener('load', function() {
			        		entry.target.setAttribute('loaded','');
			        	});

			        	observer.unobserve(entry.target)
			        }
			    });
			});

			items.each(function (index, item) {
			    observer.observe(item, index);
			});
		} else {
			items.each(function() {
				let src = $(this).attr('src');
				let alt = $(this).attr('alt');
				let width = $(this).css('--w');
				let height = $(this).css('--h');

				$(this).append(`<img src="${src}" width="${width}" height="${height}" alt="${alt}">`)
			});
		}
	};

	return new LazyImage();
})();
