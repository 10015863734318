var Alerta = (function() {
	function Alerta() {
		$('body').append('<div id="alertas"></div>');

		this.container = $('#alertas');
		this.count = 0;
	}

	Alerta.prototype.new = function(data) {
		var alerta = '<div class="alert '+(data.variant || 'alert-default')+' fade show" id="alert-'+this.count+'">'+
			'<div class="media">'+
				(data.icon ? '<div class="icon-area">'+data.icon+'</div>' : '')+
				'<div class="media-body align-self-center">'+(data.message || '')+'</div>'+
				'<button type="button" class="close" aria-label="fachar"><i class="fas fa-times"></i></button>'+
			'</div>'+
		'</div>';

		this.container.append(alerta);

		$('#alert-'+this.count+' .close').click(function() {
			$(this).parents('.alert').alert('close');
		});

		this.count++
	}

	return new Alerta();
})();
