$('.mobile-controls .btn').click(function() {
	var main = $('.main-topo');
	var btn  = $(this);

	$('.topo').append('<div class="menu-backdrop"></div>');

	$('.menu-backdrop').click(function() {
		main.removeClass('show');
		btn.removeClass('active');

		$(this).fadeOut(600, function() {
			$(this).remove();
		});
	}).fadeIn(600);

	main.addClass('show');
	btn.addClass('active').blur();
});
