var LazyBG = (function() {
	const items = $("[data-lazy-bg]");

	if(window.IntersectionObserver) {
		const observer = new IntersectionObserver((entries) => {
		    $(entries).each(function (index, entry) {
		        if (entry.isIntersecting === true) {
		        	let bg = JSON.stringify($(entry.target).data('lazy-bg'));

		        	$(entry.target).css({
		        		'--lazyBG' : `url(${bg})`
		        	}).removeAttr('data-lazy-bg');

		        	observer.unobserve(entry.target)
		        }
		    });
		}, {
			rootMargin: "0px 0px 200px 0px"
		});

		items.each(function (index, item) {
		    observer.observe(item, index);
		});

	}else {

		items.each(function (index, item) {
			let bg = item.getAttribute('data-lazy-bg');

			item.setAttribute('style', `--lazyBG: url(${bg})`);
			item.removeAttribute('data-lazy-bg');
		});

	}

})();
