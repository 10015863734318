$('.nav-planos').each(function() {
	var lis  	= $(this).find('li');
	var navs 	= lis.find('a');
	var items 	= $('.item-plano');

	navs.click(function(e) {
		e.preventDefault();
		var target = $(this).attr('href');

		lis.removeClass('active');
		$(this).parent().addClass('active');

		$('.plano').removeClass('active');
		$(target).addClass('active');
	});

});
