let dataVideos = (function(){
	let videos = $('[data-video]');

	if(videos.length > 0){
		let modalVideos = `
			<div class="modal modal-videos" id="modal-video">
				<div class="modal-dialog modal-lg">
					<div class="modal-content">
						<div class="modal-header">
							<button class="close" type="button" data-dismiss="modal" aria-label="Fechar">Fechar <i class="fas fa-times"></i></button>
						</div>
						<div class="modal-body">
							<div class="embed-responsive"></div>
						</div>
					</div>
				</div>
			</div>
		`;

		$('body').append(modalVideos);

		$('[data-video]').click(function(){
			let target = $(this).data('target');
			let videoTemplate = $(this).data('video');

			videoTemplate = $(videoTemplate).html();

			$(target).find('.embed-responsive').append(videoTemplate);

			$(this).blur();
		});

		$('.modal-videos').on('hide.bs.modal', function(){
			$(this).find('.embed-responsive iframe').remove();
		});
	}
})();
