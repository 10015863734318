$('.md-input').each(function() {
	var formControl = $(this).find('.form-control');
	var self = $(this);

	if(formControl.val() != '') {
		self.addClass('focus');
	}

	formControl.focus(function() {
		self.addClass('focus');
	});

	formControl.blur(function() {
		if($(this).val() == '') {
			self.removeClass('focus');
		}
	});
});
